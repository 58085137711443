import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import addToMailchimp from 'gatsby-plugin-mailchimp'

const Holder = styled.div`
  label {
    display: none;
  }
  h2 {
    ${props => props.theme.fluidType(2)};
    margin: 0;
  }
  button {
    width: 100%;
  }
`;

const Message = styled.div`
`;

const validationSchema = Yup.object().shape( {
  email: Yup.string()
  .email( 'Enter a Valid Email' )
  .required( 'Email is Required' ),
} );

function SubscribeForm() {

  const [ data, setData ] = useState( null );

  if ( data ) {
    return (
      <Message>
        <p dangerouslySetInnerHTML={{ __html: data.msg }}/>
      </Message>
    )
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={( values, { setSubmitting } ) => {
        setTimeout( () => {
          setSubmitting( false );

          addToMailchimp( values.email )
          .then( data => {
            setData( data );
          } )

        }, 400 );
      }}
    >
      {( { isSubmitting } ) => (
        <Form>
          <Holder>
            <h2>Subscribe for fun!</h2>
            <p>For competitions, market stall dates and other fun subscribe to our highly irregular newsletter.</p>
            <div className='field-holder'>
              <label htmlFor="email" className="label">Email</label>
              <Field className="small" aria-label="email" type="email" name="email" placeholder="Email address"/>
              <ErrorMessage name="email" component="p"/>
            </div>
            <button type="submit" disabled={isSubmitting}>Subscribe</button>
          </Holder>
        </Form>
      )}
    </Formik>
  )
}

export default SubscribeForm;
