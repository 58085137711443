import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import useOnClickOutside from '../../customHooks/useOnClickOutside';
import IconCross from '../atoms/iconCross';
import SubscribeForm from '../atoms/SubscribeForm';

const Holder = styled.div`
  background-color: ${props => props.theme.colours.transparentPink};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Inner = styled.div`
  background-color: ${props => props.theme.colours.yellow};
  border: 1px dashed;
  padding: 2rem;
  width: calc(100% - 4rem);
  max-width: 30rem;
  position: relative;
  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  button {
    color: ${props => props.theme.colours.yellow};
  }
`;

const Content = styled.div`
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
`;

function Popup() {
  const ref = useRef();
  const [ show, setShow ] = useState( true );
  useOnClickOutside( ref, () => setShow( false ) );

  if ( !show ) return null;

  return (
    <Holder>
      <Inner ref={ref}>
        <button className="close icon small" onClick={() => setShow( false )}><IconCross/></button>
        <Content>
          <SubscribeForm/>
          <p>You can also follow us on <a href="https://www.facebook.com/unicornsforeverclub" target="_blank" rel="noopener noreferrer">Facebook</a> or <a href="https://www.instagram.com/unicornsforeverclub/" target="_blank" rel="noopener noreferrer">Instagram</a></p>
        </Content>
      </Inner>
    </Holder>
  )
}

export default Popup;
