import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import Logo from '../../assets/uf_logo.inline.svg';
import { Link } from 'gatsby';

const Holder = styled.header`
  margin-bottom: 2rem;
  p {
    text-decoration: underline;
  }
`;

const LogoHolder = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  svg {
    width: 100%;
    max-width: 18rem;
    height: auto;
  }
`;

class Header extends Component {
  render() {
    return (
      <Holder>
        <LogoHolder>
          <Link to="/">
            <Logo/>
          </Link>
        </LogoHolder>
        <p><em>Number 1, worldwide, magic <br/>drawing company since 2021</em></p>
      </Holder>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header
