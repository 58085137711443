export const theme = {
  colours: {
    black: 'black',
    white: 'white',
    grey: 'grey',
    green: '#67C000',
    darkGreen: '#264D2E',
    yellow: '#FFD100',
    pink: '#FF5AAE',
    transparentPink: 'rgba(255,90,174, 0.5)',
    blue: '#2134B0',
    purple: '#6F0A4B',
  },
  typography: {
    min: 13,
    max: 13,
    minScreen: 400,
    maxScreen: 1200,
    scale: {
      min: 1.125,
      max: 1.2,
    },
    sans: '"PlexSans", sans-serif',
    mono: '"PlexMono", monospace',
  },
  // https://www.smashingmagazine.com/2016/05/fluid-typography/
  fluidType: ( exp ) => {
    return `
      font-size: ${theme.typography.min * Math.pow( theme.typography.scale.min, exp )}px;
      @media screen and (min-width: ${theme.typography.minScreen}px ) {
        font-size: calc( ${theme.typography.min * Math.pow( theme.typography.scale.min, exp )}px + (${theme.typography.max * Math.pow( theme.typography.scale.max, exp )} - ${theme.typography.min * Math.pow( theme.typography.scale.min, exp )})*(100vw - ${theme.typography.minScreen}px)/(${theme.typography.maxScreen} - ${theme.typography.minScreen}) );
      }
      @media screen and (min-width: ${theme.typography.maxScreen}px ) {
        font-size: ${theme.typography.max * Math.pow( theme.typography.scale.max, exp )}px;
      }
      `
  },
  breakpoints: {
    sm: 'min-width: 576px',
    md: 'min-width: 768px',
    lg: 'min-width: 992px',
    xl: `min-width: 1200px`,
  },
};
