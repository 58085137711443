import { createGlobalStyle } from 'styled-components';

import PlexMono from './../../assets/fonts/IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-Regular.woff';
import PlexMonoWoff from './../../assets/fonts/IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-Regular.woff2';

import PlexMonoItalic from './../../assets/fonts/IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-Italic.woff';
import PlexMonoItalicWoff from './../../assets/fonts/IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-Italic.woff2';

import PlexMonoBold from './../../assets/fonts/IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-Bold.woff';
import PlexMonoBoldWoff from './../../assets/fonts/IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-Bold.woff2';

import Plex from './../../assets/fonts/IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-Text.woff';
import PlexWoff from './../../assets/fonts/IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-Text.woff2';

import PlexBold from './../../assets/fonts/IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-Bold.woff';
import PlexBoldWoff from './../../assets/fonts/IBM-Plex-Sans/fonts/complete/woff2/IBMPlexSans-Bold.woff2';

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "PlexMono";
    font-weight: normal;
    src:  url('${PlexMono}') format("woff2"),
          url('${PlexMonoWoff}') format("woff");
  }
  
  @font-face {
    font-family: "PlexMono";
    font-weight: normal;
    font-style: italic;
    src:  url('${PlexMonoItalic}') format("woff2"),
          url('${PlexMonoItalicWoff}') format("woff");
  }
  
  @font-face {
    font-family: "PlexMono";
    font-weight: bold;
    src:  url('${PlexMonoBold}') format("woff2"),
          url('${PlexMonoBoldWoff}') format("woff");
  }
  
  @font-face {
    font-family: "PlexSans";
    font-weight: normal;
    src:  url('${Plex}') format("woff2"),
          url('${PlexWoff}') format("woff");
  }
  
  @font-face {
    font-family: "PlexSans";
    font-weight: bold;
    src:  url('${PlexBold}') format("woff2"),
          url('${PlexBoldWoff}') format("woff");
  }

  html {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      ${props => props.theme.fluidType(0)};
      * { box-sizing: border-box; }
  }

  body {
      margin: 1rem;
      // Use system fonts: https://css-tricks.com/snippets/css/system-font-stack/
      font-family: ${props => props.theme.typography.mono};
      color: ${props => props.theme.colours.darkGreen};
      background-color: ${props => props.theme.colours.pink};
      @media( ${props => props.theme.breakpoints.md} ) { 
        margin: 2rem;
      }
  }
  
  .site-body {
    p,
    ol, ul, li,
    code, kbd, pre, samp {
        line-height: 1.5;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
  
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        font-family: ${props => props.theme.typography.sans};
        line-height: 1.2;
        font-weight: normal;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    
    .sans {
      font-family: ${props => props.theme.typography.sans};
    }
  
    h1, .h1 { 
      font-weight: bold;
      ${props => props.theme.fluidType(7)}; 
      margin: 0 0 2rem 0;
    }
    h2, .h2 { ${props => props.theme.fluidType(5)}; }
    h3, .h3 { ${props => props.theme.fluidType(3)}; }
    h4, .h4 { ${props => props.theme.fluidType(1)}; }
    h5, .h5 { ${props => props.theme.fluidType(0)}; }
    h6, .h6 { ${props => props.theme.fluidType(-1)}; }
  
    li {
        margin-top: 0;
        margin-bottom: 0;
    }
  
    small, p.small { ${props => props.theme.fluidType(-1)}; }
    
    code, kbd, pre, samp {
        font-family: monospace;
        font-size: 16px;
        white-space: normal;
    }
    
    pre { font-size: 16px; }
    
    ul {
      padding-left: 1rem;
      margin: 1.5rem 0;
      list-style: circle;
    }
    
    ol {
        padding-left: 4rem;
        list-style-type: decimal;
    }
    
    video {
      width: 100%;
      height: auto;
      margin-bottom: 2rem;
    }
    
    em, i { font-style: italic; }
    
    strong, b { font-weight: bold; }
    
    blockquote {
        font-weight: bold;
        padding-left: 4rem;
    }
    
    a { 
      color: inherit;
    }
    
    sup, sub {
        vertical-align: baseline;
        position: relative;
        top: -0.4em;
    }
    
    sub { top: 0.4em; }
    
    label {
        ${props => props.theme.fluidType( -1 )};
        line-height: 1.2;
        font-weight: normal;
        margin: 0;
    }
    
    .field-holder {
      margin: 1rem 0;
      p:last-child {
        margin: 0;
        font-style: italic;
        &:before {
          content: '! ';
        }
      }
    }
    
    .text-input,
    input[type="text"],
    input[type="password"],
    input[type="date"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="range"],
    input[type="search"],
    input[type="tel"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    textarea {
        color: inherit;
        display: block;
        font-family:inherit;
        ${props => props.theme.fluidType( 0 )};
        padding: 0.4rem 1rem;
        margin: 0.2rem 0;
        border: 1px solid;
        border-radius: 0;
        line-height: 1.6;
        width: 100%;
        background-color: transparent;
        -webkit-appearance: none;
    }
    
    .file-input,
    input[type="file"],
    .radio-input,
    input[type="radio"],
    .checkbox-input,
    input[type="checkbox"],
    select {
        font-family:inherit;
    }
    
    .button,
    button,
    input[type="submit"],
    input[type="button"],
    input[type="reset"] {
        display: inline-block;
    
        padding: 0.3rem 3.5rem;
    
        color: ${props => props.theme.colours.pink};
        background-color: ${props => props.theme.colours.darkGreen};
        font-family: ${props => props.theme.typography.sans};
        ${props => props.theme.fluidType( 1 )};
        line-height: 1.8;
        text-decoration: none;
        white-space: nowrap;
        border: none;
        border-radius: 0;
        font-weight: normal;
        cursor: pointer;
        -webkit-appearance: none;
        &:disabled, 
        &[disabled]{
          background-color: ${props => props.theme.colours.grey};
          cursor: auto;
          &:hover {
            background-color: ${props => props.theme.colours.grey};
            color: ${props => props.theme.colours.pink};
          }
        }
        &:hover {
            text-decoration: none;
            color: ${props => props.theme.colours.darkGreen};
            background-color: ${props => props.theme.colours.green};
        }
        &.link {
          border: none;
          padding: 0;
          font-style: inherit;
          color: inherit;
          font-size: inherit;
          background-color: transparent;
        }
        &.link:hover {
          color: inherit;
          background-color: transparent;
          text-decoration: underline;
        }
        &.icon {
          padding: 0;
          background-color: transparent;
          border: 0;
          svg {
            height: 2rem;
            width: auto;
            display: block;
          }
          &.small {
            svg {
              height: 1rem;
            }
          }
        }
    }
  }
`;

export default GlobalStyle;
