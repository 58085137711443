import React, { useState } from 'react';
import styled from 'styled-components';
import Hamburger from '../../assets/hamburger.inline.svg';
import Close from '../../assets/close.inline.svg';
import Navigation from './Navigation';
import { Link } from 'gatsby';
import Logo from '../../assets/uf_logo-small.inline.svg';
import SmallNavigation from './SmallNavigation';

const Holder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media( ${props => props.theme.breakpoints.lg} ) { 
    display: none;
  }
  a {
    svg {
      width: 100%;
      max-width: 20rem;
      height: auto;
    }
  }
  button {
    position: relative;
    z-index: 21;
    color: ${props => props.theme.colours.yellow};
    svg {
      path { stroke: ${props => props.theme.colours.darkGreen}; }
    }
  }
`;

function SmallNavigationHolder() {
  const [ open, setOpen ] = useState( false );
  return (
    <Holder>
      <Link to="/">
        <Logo/>
      </Link>
      <button className="icon" onClick={() => setOpen( !open )}>{open ? <Close/> :
        <Hamburger/>}</button>
      {open && <SmallNavigation clickHandler={() => setOpen( !open )}/>}
    </Holder>
  )
}

export default SmallNavigationHolder;
