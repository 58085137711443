import React from 'react';
import PropTypes from 'prop-types';

import GlobalStyles from '../components/atoms/GlobalStyles';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from '../utils/styling';
import Navigation from '../components/molecules/Navigation';
import SmallNavigationHolder from '../components/molecules/SmallNavigationHolder';
import FloatingIcons from '../components/organisms/FloatingIcons';
import Popup from '../components/molecules/Popup';
import Header from '../components/molecules/Header';
import SubscribeForm from '../components/atoms/SubscribeForm';

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  @media( ${props => props.theme.breakpoints.lg} ) { 
    grid-gap: 2rem;
    grid-template-columns: 23rem 1fr;
  }
`;

const Main = styled.main`
  max-width: calc( ${props => props.theme.typography.maxScreen}px - 20rem);
`;

const LargeNavHolder = styled.div`
  display: none;
  @media( ${props => props.theme.breakpoints.lg} ) { 
    display: block;
  }
  > div {
    position: sticky;
    top: 0;
  }
`;

const SubscribeHolder = styled.div`
  border: 1px dashed;
  padding: 1rem;
  margin-top: 4rem;
`;

function Index( props ) {

  return (
    <ThemeProvider theme={theme}>
      <Holder className="site-body">
        <GlobalStyles/>
        <SmallNavigationHolder/>
        <LargeNavHolder>
          <div>
            <Header/>
            <Navigation/>
            <SubscribeHolder>
              <SubscribeForm/>
            </SubscribeHolder>
          </div>
        </LargeNavHolder>
        <Main>{props.children}</Main>
        <Popup/>
        <FloatingIcons/>
      </Holder>
    </ThemeProvider>
  )
}


Index.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Index;
