import React from 'react';
import styled from 'styled-components';
import useLockBodyScroll from '../../customHooks/useLockBodyScroll';
import Navigation from './Navigation';
import { Link } from 'gatsby';
import Logo from '../../assets/uf_logo-small.inline.svg';

const Holder = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 1rem;
  background-color: ${props => props.theme.colours.yellow};
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  @media( ${props => props.theme.breakpoints.md} ) { 
    padding: 2rem;
  }
  a {
    svg {
      width: 100%;
      max-width: 20rem;
      height: auto;
      margin-bottom: 2rem;
    }
  }
`;

const Inner = styled.div`
  margin-bottom: 3rem;
`;

function SmallNavigation( { clickHandler } ) {
  useLockBodyScroll();
  return (
    <Holder onClick={clickHandler}>
      <Inner>
        <Link to="/">
          <Logo/>
        </Link>
        <Navigation/>
      </Inner>
    </Holder>
  )
}

export default SmallNavigation;
