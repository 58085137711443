import React from 'react';
import styled from 'styled-components';
import { Link, StaticQuery, graphql } from 'gatsby';
import Cart from '../../assets/cart.inline.svg';

const Holder = styled.div`
  max-width: 25rem;
  nav {
    margin: 4rem 0;
    ul {
      list-style: none;
      padding-left: 0;
      margin: 0;
      li {
        font-family: ${props => props.theme.typography.sans};
        ${props => props.theme.fluidType( 2 )};
        margin: 0.2rem 0;
        
        &.cart {
          margin: 1rem 0;
          button {
            font-weight: bold;
          }
        }
        
        &.instagram {
          margin-top: 2rem;
        }
        &.social {
          font-weight: bold;
        }
        
        p {
          font-family: ${props => props.theme.typography.mono};
          font-style: italic;
          ${props => props.theme.fluidType( 0 )};
          text-transform: uppercase;
          margin: 1rem 0 0 1rem;
        }
        
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        svg {
          width: 1.5rem;
          height: auto;
          margin-left: 0.5rem;
          transform: translateY(0.2rem);
          path, circle {
            stroke: ${props => props.theme.colours.darkGreen};
          }
        }
        ul {
          padding-left: 2rem;
          margin: 0 0 1rem 0;
          list-style: circle;
          li {
            font-family: ${props => props.theme.typography.mono};
            ${props => props.theme.fluidType( 0 )};
            margin: 0;
          }
        }
      }
    }
  }
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
`;

function Navigation() {

  return (
    <Holder>
      <nav>
        <ul>
          <li><Link to="/products">Products</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/updates">Updates</Link></li>
          <li><Link to="/recycle">Recycle</Link></li>
          <li><Link to="/contact">Contact</Link></li>
          <li className="social instagram">
            <em><a href="https://www.instagram.com/unicornsforeverclub/" target="_blank" rel="noopener noreferrer">Instagram</a></em>
          </li>
          <li className="social">
            <em><a href="https://www.facebook.com/unicornsforeverclub" target="_blank" rel="noopener noreferrer">Facebook</a></em>
          </li>
          <li className="social">
            <em><a href="https://www.etsy.com/au/shop/unicornsforeverclub" target="_blank" rel="noopener noreferrer">Etsy</a></em>
          </li>
        </ul>
      </nav>
    </Holder>
  )
}

export default Navigation;
