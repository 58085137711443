import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import Icon1 from '../../assets/icon_snake.inline.svg';
import Icon2 from '../../assets/icon_apple.inline.svg';
import Icon3 from '../../assets/icon_dragon.inline.svg';
import Icon4 from '../../assets/icon_banana.inline.svg';
import Icon5 from '../../assets/icon_dolphin.inline.svg';
import Icon6 from '../../assets/icon_elephant.inline.svg';
import Icon7 from '../../assets/icon_lightning.inline.svg';
import Icon8 from '../../assets/icon_loveheart.inline.svg';
import Icon9 from '../../assets/icon_mushroom.inline.svg';
import Icon10 from '../../assets/icon_sneaky.inline.svg';
import Icon11 from '../../assets/icon_star.inline.svg';
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import useWindowSize from '../../customHooks/useWindowSize';

gsap.registerPlugin( MotionPathPlugin );

const Holder = styled.div`
  position: fixed;
  width: 60px;
  top: calc(50% - 30px);
  left: -100px;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    object-fit: contain;
    display: block;
  }
`;

const icons = [
  <Icon1/>,
  <Icon2/>,
  <Icon3/>,
  <Icon4/>,
  <Icon5/>,
  <Icon6/>,
  <Icon7/>,
  <Icon8/>,
  <Icon9/>,
  <Icon10/>,
  <Icon11/>,
];

function FloatingIcons() {
  const holder = useRef( null );
  const tl = useRef( null );
  const windowSize = useWindowSize();

  useEffect( () => {

    gsap.set( '.icon, .icon svg', { clearProps: true } );

    if ( tl.current ) {
      tl.current.clear();
    }

    let randomX = gsap.utils.random( 0, windowSize.width / 2, true );
    let randomY = gsap.utils.random( window.innerHeight / 2 * -1, window.innerHeight / 2, true );

    tl.current = gsap.timeline();

    for ( let i = 0; i < icons.length; i++ ) {
      tl.current.add( gsap.timeline( {
        repeat: -1,
        delay: i * 1,
      } ).to( `.icon-${i + 1}`, {
        motionPath: [
          { x: randomX(), y: randomY() },
          { x: randomX() + windowSize.width / 2, y: randomY() },
          { x: windowSize.width + 100, y: randomY() },
        ],
        ease: 'none',
        duration: 10,
      } ).fromTo( `.icon-${i + 1} svg`, {
        rotate: -30,
      }, {
        rotate: 30,
        duration: 1,
        repeat: 9,
        yoyo: true,
      }, 0 ).to( `.icon-${i + 1}`, {
        x: 0,
        y: 0,
        duration: 0,
      } ), 0 );
    }

  }, [ windowSize.width ] );

  return (
    <Holder ref={holder}>
      {icons.map( ( icon, i ) =>
        <div key={i} className={`icon icon-${i + 1}`}>
          {icon}
        </div>
      )}
    </Holder>
  )
}

export default FloatingIcons;
